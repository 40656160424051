<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div class="container shape-container d-flex align-items-center">
          <div class="col px-0">
            <div class="row justify-content-center mt-5">
              <div class="col-lg-5">
                <h2 class="text-primary mt-4 display-1 f-bold text-head mb-3">
                  Energy Audit
                </h2>
                <p class="f-16 text-dark mb-0">
                  To enable us provide the right solution, Imperium will carry
                  out a general review of your current energy consumption and
                  need. At the end of this exercise, we will ascertain the
                  critical load that needs to be on the renewable energy source
                </p>
              </div>
              <div class="col-md-1"></div>
              <div class="col-md-6">
                <form @submit.prevent="submitForm()" class="my-5">
                  <div class="row">
                    <div class="form-group contactForm col-md-6">
                      <label>First name</label>
                      <input
                        v-model="first_name"
                        required
                        class="form-control"
                        type="text"
                      />
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>Last name</label>
                      <input
                        v-model="last_name"
                        required
                        class="form-control"
                        type="text"
                      />
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>Email address</label>
                      <input
                        v-model="email"
                        required
                        class="form-control"
                        type="email"
                      />
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>Phone number</label>
                      <input
                        v-model="phone_number"
                        required
                        class="form-control"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        type="number"
                        maxlength="13"
                      />
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>Contact preference</label>
                      <select
                        v-model="contact_reference"
                        required="required"
                        class="form-control"
                      >
                        <option
                          value=""
                          selected="selected"
                          disabled="disabled"
                        ></option>
                        <option value="phone">Phone</option>
                        <option value="email">Email</option>
                        <option value="phone-email">
                          Both Phone and Email
                        </option>
                      </select>
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>Contact Date</label>
                      <input
                        v-model="contact_date"
                        required
                        class="form-control"
                        :min="tomorrow"
                        type="date"
                      />
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>Address</label>
                      <input
                        v-model="address.street"
                        required
                        class="form-control"
                        type="text"
                      />
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>Description of the house address </label>
                      <input
                        v-model="address.more_details"
                        required
                        class="form-control"
                        placeholder="e.g Storey, building bungalow, etc"
                        type="text"
                      />
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>State</label>
                      <select
                        v-model="address.state"
                        name="state"
                        required
                        class="form-control"
                        @change="address.lga = ''"
                      >
                        <option disabled selected value="">State</option>
                        <option v-for="(item, index) in allStates" :key="index">
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group contactForm col-md-6">
                      <label>LGA</label>
                      <select
                        required
                        v-model="address.lga"
                        placeholder="LGA"
                        class="form-control text-dark"
                      >
                        <option disabled selected value="">LGA</option>
                        <option v-for="(item, index) in lgas" :key="index">
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <button
                      :disabled="loading"
                      class="
                        btn-block btn btn-imp-secondary
                        bg-dark
                        text-white
                        mt-5
                        py-3
                        px-5
                      "
                      type="submit"
                    >
                      <span class="nav-link-inner--text">Send Request</span>
                      <BtnLoading v-if="loading" class="btn-loading" />
                    </button>
                    <p class="text-center my-3">
                      By clicking the button, you agree to be contacted by the
                      Imperium team.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
import NaijaStates from "naija-state-local-government";

export default {
  components: { BtnLoading },
  data() {
    return {
      allStates: NaijaStates.states(),
      loading: false,
      email: "",
      phone_number: "",
      contact_reference: "",
      contact_date: "",
      address: {
        house_number: "",
        street: "",
        lga: "",
        state: "",
        more_details: "",
      },
      first_name: "",
      last_name: "",
    };
  },
  computed: {
    tomorrow() {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate.toLocaleDateString("en-ca");
    },
    lgas() {
      if (this.address.state) {
        var lg = NaijaStates.lgas(this.address.state).lgas;
        return lg;
      }
    },
  },
  methods: {
    submitForm() {
      var reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

      if (this.email.match(reg)) {
        this.loading = true;
        let data = {
          email: this.email,
          phone_number: this.phone_number,
          contact_name: this.contact_reference,
          contact_date: this.contact_date,
          house_address: this.address.street,
          address_description: this.address.more_details,
          first_name: this.first_name,
          last_name: this.last_name,
        };
        data = this.$helpers.encrypt(JSON.stringify(data));

        data = {
          data,
        };
        let payload = {
          data,
          path: "/energyAuditRequest",
        };
        this.$store
          .dispatch("postRequest", payload)
          .then((resp) => {
            let decr = JSON.parse(this.$helpers.decrypt(resp.data.data));
            resp.data = decr;
            console.log(resp);
            this.loading = false;
            this.email = "";
            this.phone_number = "";
            this.contact_reference = "";
            this.contact_date = "";
            this.address = "";
            this.first_name = "";
            this.last_name = "";

            this.$toast.success(
              "Request Energy Audit",
              "Your Request has been sent successfully",
              this.$toastPosition
            );
            this.$router.push("/");
          })
          .catch((err) => {
            if (err.response) {
              let decr = JSON.parse(
                this.$helpers.decrypt(err.response.data.data)
              );
              err.response.data = decr;
              this.$toast.info(
                "Request Energy Audit",
                err.response.data.message,
                this.$toastPosition
              );
            } else {
              this.$toast.info(
                "Request Energy Audit",
                "Unable to submit request, check the details and try again",
                this.$toastPosition
              );
            }
            this.loading = false;
          });
      } else {
        this.$toast.info(
          "Energy Audit Request",
          "Invalid Email",
          this.$toastPosition
        );
      }
    },
  },
};
</script>
<style lang="scss">
#terms-and-conditions {
  color: black;
  h3 {
    color: #219653;
    opacity: 0.8;
    font-weight: bold;
  }
  h4 {
    color: black;
    font-size: 18px;
    font-weight: bold;
  }
}
#terms-and-conditions .details table {
  width: 100%;
  font-size: 1.1rem;
  border-collapse: collapse;
  margin-bottom: 2rem;
  th {
    font-weight: 600;
  }
}
#terms-and-conditions .details table td,
#terms-and-conditions .details table th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
  padding-left: 2rem;
}
.contactForm {
  input,
  textarea,
  select {
    border: 1px solid #219653;
    border-radius: 15px;
    color: black;
    &:hover,
    &:focus {
      color: black;
    }
  }
}
.locateInfo {
  background-color: #fff7d6;
  background-image: url("/assets/images/svgs/locateInfobg.svg");
  background-position: center;
  background-size: cover;
  border-radius: 30px;
  padding: 50px 0px;

  div.locateDiv {
    text-align: center;
    border-right: 1px solid #219653 !important;
    min-height: 156px;
    &.last {
      border: none !important;
    }
    img {
      margin: 20px 0px;
    }
    p {
      font-size: 18px;
      text-align: center;
      color: #202020;
    }
  }
}
@media only screen and (max-width: 768px) {
  .contactbannerimg {
    width: 80%;
  }
  .locateInfo {
    div.locateDiv {
      border: none !important;
    }
  }
}
</style>
<style >
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>